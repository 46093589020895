







import TopMenu from '@/components/Menubar/TopMenu.vue';
import SideBar from '@/components/Menubar/SideBar.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'Main',
  components: {
    TopMenu,
    SideBar,
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'currentUserStorage/isLoggedIn',
    }),
  },
};
