var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-list',{staticClass:"menu-list"},[(_vm.canSeeDashboard)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-list-item',_vm._g({staticClass:"menu",attrs:{"link":"","to":_vm.$t('route-path.dashboard'),"active-class":"active"}},on),[_c('v-list-item-content',[_c('Home',{staticClass:"icon"})],1)],1)]}}],null,false,678022005)},[_c('span',[_vm._v(_vm._s(_vm.$t('side-menu.dashboard')))])]):_vm._e(),(_vm.canSeeDocuments)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-list-item',_vm._g({staticClass:"menu",attrs:{"link":"","to":_vm.$t('route-path.documents'),"active-class":"active"}},on),[_c('v-list-item-content',[_c('Folder',{staticClass:"icon"})],1)],1)]}}],null,false,1701890196)},[_c('span',[_vm._v(_vm._s(_vm.$t('side-menu.documents')))])]):_vm._e(),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-list-item',_vm._g({staticClass:"menu",class:{ active: _vm.isBasicInformationPage },attrs:{"link":"","to":_vm.$t('route-path.organizational-map'),"active-class":"active"}},on),[_c('v-list-item-content',[_c('BasicInformation',{staticClass:"icon"})],1)],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('side-menu.basic-information')))])]),(_vm.isAdmin)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-list-item',_vm._g({staticClass:"menu",class:{ active: _vm.isAdminPage },attrs:{"link":"","to":("" + (_vm.$t('route-path.admin-settings').toString()) + (_vm.$t('route-path.admin-users').toString()))}},on),[_c('v-list-item-content',[_c('Admin',{staticClass:"icon admin"})],1)],1)]}}],null,false,1640887396)},[_c('span',[_vm._v(_vm._s(_vm.$t('side-menu.admin-settings')))])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }