


























































import Vue from 'vue';
import Home from '@/../public/images/home.svg?inline';
import Folder from '@/../public/images/folder.svg?inline';
import Admin from '@/../public/images/tool.svg?inline';
import BasicInformation from '@/../public/images/basic-information.svg?inline';
import { mapGetters } from 'vuex';

export default Vue.extend({
  name: 'SideBar',
  components: {
    Home,
    Folder,
    Admin,
    BasicInformation,
  },
  computed: {
    ...mapGetters({
      isAdmin: 'currentUserStorage/isAdmin',
    }),
  },
});
